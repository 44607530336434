<template>
  <moe-page title="运费管理" class="freight">
    <moe-inquire @search="freightTplSearch">
      <el-form-item label="模板名称">
        <el-input v-model.trim="freightTplParams.name" placeholder="输入模板名称" maxlength="100" clearable />
      </el-form-item>
      <el-form-item label="供应商">
        <el-select v-model="freightTplParams.vendorId" filterable placeholder="请选择供应商" clearable>
          <el-option
            v-for="(item, index) in supplierList"
            :key="index"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
    </moe-inquire>
    <div class="df jcfe mt-20 mb-30">
      <el-button slot="tool" type="primary" icon="el-icon-circle-plus-outline" @click="$moe_coordinator.navigateTo('/mallManage/freightManage/add')">新增运费管理</el-button>
    </div>

    <template v-if="tableData.length">
      <div v-for="(item, index) in tableData" :key="index">
        <moe-table :data="item.configList" ref="freightTplTableRef" url="" :params="{ pageNum: 1 }" :numberShow="false" class="content" :mode="false">
          <template slot="tool">
            <div class="dg dg4 aic">
              <div class="df aic mr-20">
                <div class="mr-10 fwb ma">{{ item.name }}</div>
                <!-- <el-tag  v-if="item.isDefault" effect="dark" type="danger">默认模板 </el-tag> -->
              </div>
              <div class="mr-20 wsn">关联商品：{{ item.goodsCount }}</div>
              <div class="mr-20 wsn">供应商: {{ item.vendorName }}</div>
              <div class="mr-20 wsn">更新时间：{{ $moe_time.getTimeStamp(item.updateTime) }}</div>
            </div>
            <div class="wsn">
              <!-- <el-button :disabled="item.isDefault" type="primary" plain size="mini" icon="el-icon-house" @click="req_asDefaultFreightTpl(item)">设为默认</el-button> -->
              <el-button type="warning" plain size="mini" icon="el-icon-edit" @click="$moe_coordinator.navigateTo(`/mallManage/freightManage/add`, { id: item.id })">编辑</el-button>
              <el-button type="success" plain size="mini" icon="el-icon-copy-document" @click="req_copyFreightTpl(item)">复制模版</el-button>
              <el-button type="danger" plain size="mini" icon="el-icon-delete" @click="req_delFreightTplDetail(item)">删除</el-button>
            </div>
          </template>
          <el-table-column label="可配送区域" min-width="80">
            <template slot-scope="{ row }">
                <div>{{ formatterAreas(row) }}</div>
              </template>
          </el-table-column>
          <el-table-column :label="`${item.method === 'PIECE' ? '首件（个）' : '首重（KG）'}`" min-width="80">
            <template slot-scope="{ row }">
                <div>{{ row.firstCondition }}</div>
              </template>
          </el-table-column>
          <el-table-column label="运费（元）" min-width="80">
            <template slot-scope="{ row }">
                <div>{{ row.firstPrice }}</div>
              </template>
          </el-table-column>
          <el-table-column :label="`${item.method === 'PIECE' ? '续件（个）' : '续重（KG）'}`" min-width="80">
            <template slot-scope="{ row }">
                <div>{{ row.continueCondition }}</div>
              </template>
          </el-table-column>
          <el-table-column label="续费（元）" min-width="80">
            <template slot-scope="{ row }">
                <div>{{ row.continuePrice }}</div>
              </template>
          </el-table-column>
        </moe-table>
      </div>
    </template>
    <moe-table v-else :data="tableData" ref="freightTplTableRef" url="" :params="{ pageNum: 1 }" :numberShow="false" :mode="false" :toolShow="false"></moe-table>
  </moe-page>
</template>

<script>
export default {
  name: 'FreightManageList',
  data() {
    return {
      freightTplParams: {
        pageNum: 1,
        pageSize: 999,
        name: '',
        vendorId: '',
      },
      tableData: [],
      supplierList: [],
    }
  },
  methods: {
    freightTplSearch(isSearch) {
      if (!isSearch) {
        this.freightTplParams.name = '';
        this.freightTplParams.vendorId = '';
      }
      this.getFreightTplList();
    },
    formatterAreas({ areas }) {
      if (areas.length >= 3) {
        return `${areas.slice(0, 3).join('、')}等`;
      }
      return areas.join('、')
    },
    /** 设为默认运费模板 */
    req_asDefaultFreightTpl({ name, id }) {
      this.$moe_layer.confirm(`您确定要设置"${name}"为默认模版吗？`, () => {
        this.$moe_api.FREIGHTTPL_API.asDefaultFreightTpl({ id }).then((data) => {
          if (data.code == 200) {
            this.$moe_msg.success('设置成功 !', {
              completionHandler: () => {
                this.getFreightTplList();
              }
            });
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      });
    },
    /** 复制运费模板 */
    req_copyFreightTpl({ name, id }) {
      this.$moe_layer.confirm(`您确定要复制"${name}"模版吗？`, () => {
        this.$moe_api.FREIGHTTPL_API.copyFreightTpl({ id }).then((data) => {
          if (data.code == 200) {
            this.$moe_msg.success('复制成功 !', {
              completionHandler: () => {
                this.getFreightTplList();
              }
            });
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      });
    },
    /** 删除运费模板 */
    req_delFreightTplDetail({ name, id }) {
      this.$moe_layer.confirm(`您确定要删除"${name}"模版吗？`, () => {
        this.$moe_api.FREIGHTTPL_API.delFreightTplDetail({ id }).then((data) => {
          if (data.code == 200) {
            this.$moe_msg.success('删除成功 !', {
              completionHandler: () => {
                this.getFreightTplList();
              }
            });
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      });
    },
    /* 分页查询运费模板 */
    getFreightTplList() {
      this.$moe_api.FREIGHTTPL_API.getFreightTplList(this.freightTplParams).then((data) => {
        if (data.code === 200) {
          if (this.tableData.length) {
            this.tableData.splice(0);
          }
          this.$nextTick(() => {
            this.tableData = data.result.list;
          })
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    },
    /** 分页查询供应商列表 */
    req_getVendorList() {
      this.$moe_api.SUPPLIER_API.getVendorList({ pageNum: 1, pageSize: 999, status: true }).then((data) => {
        if (data.code === 200) {
          this.supplierList = data.result.list.map(({ name, id }) => {
            return {
              label: name,
              value: id
            }
          });
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    },
  },
  mounted() {
    this.req_getVendorList();
    this.getFreightTplList();
  }
}
</script>

<style lang="scss">
.freight {
  .moe-table__tool{
    background: #f2f2f2;
    margin-left: 10px;
    margin-right: 10px;
    border: 1px solid #e5e5e5;
  }
  .moe-table__page{
    height: 10px;
  }
  .moe-table__cont th{
    background-color: #fff;
  }
  .moe-table__tool{
    justify-content: space-between;
  }
}
</style>